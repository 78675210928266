import React, { Component, useEffect } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
// import { usePagination } from './utils/services/pagination copy';
const loading = () => {
  return <div className="animated fadeIn pt-3 text-center"><div className="sk-spinner sk-spinner-pulse"></div></div>;
}

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));
class App extends Component {
  render() {
    return (
      <HashRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <Route path="/" name="Home" render={props => <DefaultLayout {...props}/>} />
            </Switch>
          </React.Suspense>
      </HashRouter>
    );
  }
}

// const App = () => {
  // const [data, controller] = usePagination("https://api.dcfoodbank.com/api/products/", { limit: 1 })
  // useEffect(() => {
  //   controller.load()
  // }, [])
  // return <div>
  //   <div className='d-flex'>
  //     <button onClick={() => controller.prev()} disabled={!controller.canPrev()}>Prev</button>
  //     {controller.pageBuilder((page) => <button onClick={() => controller.fetch(page)} disabled={controller.page === page}>{page}</button>)}
  //     <button onClick={() => controller.next()} disabled={!controller.canNext()}>Next</button></div>
  //   {JSON.stringify({ data: data?.map((data) => data.id), meta: controller.meta, page: controller.page, total: controller.totalPage() })}
  // </div>



// }
export default App;
